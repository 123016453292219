import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class TicketService {
  public readonly openTicketSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly companyOpenTicketSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly ticketSummarySubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly companyTicketSummarySubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly ticketPrioritiesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly companyTicketPrioritiesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public readonly medianResponseTimeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private readonly rest: RestService) {}

  fetchAll(): void {
    this.getOpenTickets();
    this.getCompanyOpenTickets();
    this.getTicketSummary();
    this.getCompanyTicketSummary();
    this.getTicketPriorities();
    this.getCompanyTicketPriorities();
    this.getMedianResponseTime();
  }

  getOpenTickets(): void {
    this.rest.get(`${environment.api}/ticket/open`).then(res => {
      this.openTicketSubject.next(res);
    });
  }

  getCompanyOpenTickets(): void {
    this.rest
      .post(`${environment.api}/ticket/search/admin`, {
        statusOpen: true,
        pageSize: 1000
      })
      .then(res => {
        this.companyOpenTicketSubject.next(res);
      });
  }

  getTicketSummary(): void {
    this.rest.get(`${environment.api}/ticket/summary`).then(res => {
      this.ticketSummarySubject.next(res);
    });
  }

  getCompanyTicketSummary(): void {
    this.rest.get(`${environment.api}/ticket/summary/company`).then(res => {
      this.companyTicketSummarySubject.next(res);
    });
  }

  getTicketPriorities(): void {
    this.rest.get(`${environment.api}/ticket/priorities`).then(res => {
      this.ticketPrioritiesSubject.next(res);
    });
  }

  getCompanyTicketPriorities(): void {
    this.rest.get(`${environment.api}/ticket/priorities/company`).then(res => {
      this.companyTicketPrioritiesSubject.next(res);
    });
  }

  getMedianResponseTime(): void {
    this.rest.get(`${environment.api}/ticket/median-response-time`).then(res => {
      this.medianResponseTimeSubject.next(res.data);
    });
  }

  /**
   * Gets bar graph data using user summary
   * @return {any[]} [description]
   */
  getBarData(summary: any): any[] {
    let barInfo = [];

    let summaryLength = 0;
    for (const s in summary) {
      summaryLength++;
    }

    let counter = summaryLength - 12;

    for (const s in summary) {
      if (counter === 0) {
        if (s.length > 4) {
          const barObject = {
            name:
              s.length === 6
                ? this.getMonthAbbreviation(Number(s.slice(-1)))
                : this.getMonthAbbreviation(Number(s.slice(-2))),
            value: Number(summary[s])
          };

          barInfo.push(barObject);
        }
      } else {
        counter--;
      }
    }

    return barInfo;
  }

  /**
   * Gets company dashboard cards using summary data
   * @return {any[]} [description]
   */
  getDashboardCards(summary: any): any[] {
    const date: Date = new Date();

    return [
      {
        number: Number(summary[`${date.getFullYear()}-${date.getMonth() + 1}`]),
        text: 'Tickets this month'
      },
      {
        number: Number(summary[`${date.getFullYear()}`]),
        text: 'Tickets this year'
      },
      {
        number: Number(summary[`${date.getFullYear() - 1}`]),
        text: 'Tickets last year'
      }
    ];
  }

  /**
   * Gets pie graph data using user summary
   * @return {any[]} [description]
   */
  getPieData(priorities: any): any[] {
    const pieInfo = [];

    for (const p in priorities) {
      const pieObject = {
        name: priorities[p].name.split(' ')[3],
        value: Number(priorities[p].count)
      };

      pieInfo.push(pieObject);
    }

    return pieInfo;
  }

  getMonthAbbreviation(month: number): string {
    switch (month) {
      case 1:
        return 'Jan';
      case 2:
        return 'Feb';
      case 3:
        return 'Mar';
      case 4:
        return 'Apr';
      case 5:
        return 'May';
      case 6:
        return 'Jun';
      case 7:
        return 'Jul';
      case 8:
        return 'Aug';
      case 9:
        return 'Sep';
      case 10:
        return 'Oct';
      case 11:
        return 'Nov';
      case 12:
        return 'Dec';
    }
  }
}
