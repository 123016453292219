import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticFormService {
  private readonly formDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getPdfData(): any {
    return this.formDataSubject.getValue();
  }

  setPdfData(data: any): void {
    this.formDataSubject.next(data);
  }
}
