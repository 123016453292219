import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JWTService } from './jwt.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./jwt.service";
var RestService = /** @class */ (function () {
    function RestService(http, jwt, generateXhrLogs) {
        this.http = http;
        this.jwt = jwt;
        this.generateXhrLogs = generateXhrLogs;
    }
    /**
     * @description Generates a console log
     * @param {string} type
     * @param {string} url
     * @param {*} [dataSent]
     * @param {*} [dataReturned]
     */
    RestService.prototype.generateLogs = function (type, url, dataSent, dataReturned) {
        console.log("%c " + type.toUpperCase() + " API CALL TO", 'background-color: #333; color: #98bccd;');
        console.log(url);
        console.log("%c DATA SENT", 'background-color: #333; color: #fac5c5;');
        console.table(dataSent);
        console.log("%c DATA RETURNED", 'background-color: #333; color: #f5f5f5;');
        console.table(dataReturned);
    };
    /**
     * @description Abstracts generating an HTTP promise
     * @param {string} httpMethod
     * @param {string} url
     * @param {*} [body=null]
     * @returns {Promise<any>}
     */
    RestService.prototype.generateHttpPromise = function (httpMethod, url, body) {
        var _this = this;
        if (body === void 0) { body = null; }
        var promise = null;
        if (body) {
            promise = this.http[httpMethod](url, body, {
                headers: this.buildOptions()
            })
                .toPromise()
                .catch(function (err) { return _this.handleError(err); });
        }
        else {
            promise = this.http[httpMethod](url, {
                headers: this.buildOptions()
            })
                .toPromise()
                .catch(function (err) { return _this.handleError(err); });
        }
        if (!environment.production) {
            promise.then(function (res) {
                if (_this.generateXhrLogs) {
                    _this.generateLogs(httpMethod, url, body, res);
                }
            });
        }
        return promise;
    };
    /**
     * Abstracts HTTP POST
     * @param  {string}       url
     * @param  {any}          body
     * @return {Promise<any>}
     */
    RestService.prototype.post = function (url, body) {
        return this.generateHttpPromise('post', url, body);
    };
    /**
     * Abstracts HTTP GET
     * @param  {string}       url
     * @return {Promise<any>}
     */
    RestService.prototype.get = function (url) {
        return this.generateHttpPromise('get', url);
    };
    /**
     * Abstracts HTTP GET
     * @param  {string}       url
     * @return {Promise<any>}
     */
    RestService.prototype.resolve = function (url) {
        return this.http.get(url, {
            headers: this.buildOptions()
        });
    };
    /**
     * Abstracts HTTP DELETE
     * @param  {string}       url
     * @return {Promise<any>}
     */
    RestService.prototype.delete = function (url) {
        return this.generateHttpPromise('delete', url);
    };
    /**
     * Abstracts HTTP PUT
     * @param  {string}       url
     * @param  {any}          body
     * @return {Promise<any>}
     */
    RestService.prototype.put = function (url, body) {
        return this.generateHttpPromise('put', url, body);
    };
    /**
     * Abstracts HTTP PATCH
     * @param  {string}       url
     * @param  {any}          body
     * @return {Promise<any>}
     */
    RestService.prototype.patch = function (url, body) {
        return this.generateHttpPromise('patch', url, body);
    };
    /**
     * Lets use set build options with auth token header on abstracted HTTP calls
     * @return {RequestOptionsArgs}
     */
    RestService.prototype.buildOptions = function () {
        return new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.jwt.checkToken()
        });
    };
    /**
     * Error handling for all abstracted calls, doesn't reject a Promise
     * @param  {any}          serverError
     */
    RestService.prototype.handleError = function (serverError) {
        try {
            console.log('Caught try', serverError);
        }
        catch (e) {
            console.log('Caught catch', e);
        }
    };
    RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JWTService), i0.ɵɵinject("generateXhrLogs", 8)); }, token: RestService, providedIn: "root" });
    return RestService;
}());
export { RestService };
