import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

interface PdfData {}

@Injectable({
  providedIn: 'root'
})
export class AssetInventoryService {
  private readonly pdfDataSubject: BehaviorSubject<PdfData> = new BehaviorSubject<PdfData>(null);

  getPdfData(): PdfData {
    return this.pdfDataSubject.getValue();
  }

  setPdfData(data: PdfData): void {
    this.pdfDataSubject.next(data);
  }
}
