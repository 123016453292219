import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var StaticFormService = /** @class */ (function () {
    function StaticFormService() {
        this.formDataSubject = new BehaviorSubject(null);
    }
    StaticFormService.prototype.getPdfData = function () {
        return this.formDataSubject.getValue();
    };
    StaticFormService.prototype.setPdfData = function (data) {
        this.formDataSubject.next(data);
    };
    StaticFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StaticFormService_Factory() { return new StaticFormService(); }, token: StaticFormService, providedIn: "root" });
    return StaticFormService;
}());
export { StaticFormService };
