var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var NavigationService = /** @class */ (function () {
    function NavigationService(auth) {
        var _this = this;
        this.auth = auth;
        this.defaultMenuItems = [
            {
                name: 'Dashboard',
                icon: 'i-bar-chart',
                link: '/Dashboard',
                type: 'link'
            },
            {
                name: 'Support Requests',
                icon: 'i-consulting',
                link: '/MySupportTickets',
                type: 'link'
            },
            {
                name: 'My IT Team',
                icon: 'i-conference',
                link: '/MyTeam',
                type: 'link'
            }
        ];
        this.hrManagerMenuItems = [
            {
                name: 'Employee Management',
                icon: 'i-id-2',
                type: 'link',
                link: '/Admin/EmployeeManagement'
            }
        ];
        this.adminMenuItems = [
            {
                name: 'Admin',
                icon: 'i-gear',
                type: 'dropdown',
                children: [
                    {
                        name: 'Invoices',
                        icon: 'i-receipt-4',
                        type: 'link',
                        link: '/Admin/Invoices'
                    },
                    {
                        name: 'Reports',
                        icon: 'i-files',
                        type: 'dropdown',
                        children: [
                            {
                                name: 'Service Tickets',
                                type: 'link',
                                link: '/Admin/TicketReport'
                            },
                            {
                                name: 'Management Reports',
                                type: 'link',
                                link: '/Admin/ManagementReport'
                            },
                            {
                                name: 'Patch Compliance',
                                type: 'link',
                                link: '/Admin/PatchComplianceReport',
                                beta: true
                            },
                            {
                                name: 'Antivirus',
                                type: 'link',
                                link: '/Admin/AntivirusReport',
                                beta: true
                            }
                        ]
                    },
                    {
                        name: 'Asset Inventory',
                        icon: 'i-computer-2',
                        type: 'link',
                        link: '/Admin/AssetInventory',
                        beta: true
                    },
                    {
                        name: 'Employee Management',
                        icon: 'i-id-2',
                        type: 'link',
                        link: '/Admin/EmployeeManagement'
                    },
                    {
                        name: 'Workflows',
                        icon: 'i-check',
                        type: 'link',
                        link: '/Admin/Workflows/Policies'
                    },
                    {
                        name: 'Company Settings',
                        icon: 'i-gears',
                        type: 'link',
                        link: '/Admin/Settings'
                    }
                ]
            }
        ];
        this.staffMenuItems = [
            {
                name: 'Varsity Staff Panel',
                icon: 'i-administrator',
                link: '/StaffPanel',
                type: 'link'
            }
        ];
        this.sidebarState = {
            sidenavOpen: true,
            childnavOpen: false
        };
        this.menuItems = new BehaviorSubject(this.defaultMenuItems);
        this.routerOptions = {
            scrollToTop: false
        };
        this.auth.securityLevel.subscribe(function (securityLevel) {
            switch (securityLevel) {
                case 1:
                case 2:
                case 3:
                    _this.buildUserMenu();
                    break;
                case 4:
                case 5:
                    _this.buildHrManagerMenu();
                    break;
                case 6:
                    _this.buildAdminMenu();
                    break;
            }
        });
        this.menuItems$ = this.menuItems.asObservable();
    }
    NavigationService.prototype.buildUserMenu = function () {
        this.menuItems.next(__spread(this.defaultMenuItems));
        this.checkStaffPanelAccess();
    };
    NavigationService.prototype.buildHrManagerMenu = function () {
        this.menuItems.next(__spread(this.defaultMenuItems, this.hrManagerMenuItems));
        this.checkStaffPanelAccess();
    };
    NavigationService.prototype.buildAdminMenu = function () {
        this.menuItems.next(__spread(this.defaultMenuItems, this.adminMenuItems));
        this.checkStaffPanelAccess();
    };
    NavigationService.prototype.checkStaffPanelAccess = function () {
        if (this.auth.getUser().staffFlag) {
            var menuItems = this.menuItems.getValue();
            this.menuItems.next(__spread(menuItems, this.staffMenuItems));
        }
    };
    NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.AuthService)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
