import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AssetInventoryService = /** @class */ (function () {
    function AssetInventoryService() {
        this.pdfDataSubject = new BehaviorSubject(null);
    }
    AssetInventoryService.prototype.getPdfData = function () {
        return this.pdfDataSubject.getValue();
    };
    AssetInventoryService.prototype.setPdfData = function (data) {
        this.pdfDataSubject.next(data);
    };
    AssetInventoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssetInventoryService_Factory() { return new AssetInventoryService(); }, token: AssetInventoryService, providedIn: "root" });
    return AssetInventoryService;
}());
export { AssetInventoryService };
