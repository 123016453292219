var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BaseEnvironment } from './base';
export var environment = __assign({}, BaseEnvironment, { api: 'https://dev-api.varsitytech.com', production: true, titlePrefix: BaseEnvironment.titlePrefix, showMetaFields: true });
