import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { USER_ROLE } from '../common/consts';

@Injectable()
export class IsLoggedInAuthGuardService implements CanActivate {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/Login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }
  }
}

@Injectable()
export class IsAdminAuthGuardService implements CanActivate {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.loggedIn() && this.auth.getUser() && this.auth.getUser().securityLevel >= USER_ROLE.ADMIN) {
      return true;
    } else {
      this.router.navigate(['/Login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }
  }
}

@Injectable()
export class IsHrManagerAuthGuardService implements CanActivate {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.loggedIn() && this.auth.getUser() && this.auth.getUser().securityLevel >= USER_ROLE.HR_MANAGER) {
      return true;
    } else {
      this.router.navigate(['/Login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }
  }
}

@Injectable()
export class IsStaffAuthGuardService implements CanActivate {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.loggedIn() && this.auth.getUser() && this.auth.getUser().staffFlag) {
      return true;
    } else {
      this.router.navigate(['/Login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }
  }
}
