import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { GestureConfig } from '@angular/material/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

import { AssetInventoryService } from './services/asset-inventory.service';
import { AuthService } from './services/auth.service';
import {
  IsLoggedInAuthGuardService,
  IsHrManagerAuthGuardService,
  IsAdminAuthGuardService,
  IsStaffAuthGuardService
} from './services/auth-guard.service';
import { JWTService } from './services/jwt.service';
import { NavigationService } from './services/navigation.service';
import { PatchComplianceService } from './services/patch-compliance.service';
import { RestService } from './services/rest.service';
import { TicketService } from './services/ticket.service';
import { StaticFormService } from './services/static-form.service';

import { UnsafeOperationDialog } from './services/operation-insecure';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { version } from '../../package.json';

import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
import { DndModule } from 'ng2-dnd';

Sentry.init({
  dsn: 'https://660a602bed53462d989341e9638ed4f6@sentry.io/1285874',
  release: 'varsity-portal-front@' + version,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
  enabled: environment.production ? true : false
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

const routes: Routes = [
  {
    path: 'Login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'Logout',
    loadChildren: () => import('./main-portal/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'RegistrationConfirmation',
    loadChildren: () =>
      import('./register-confirmation/register-confirmation.module').then(m => m.RegisterConfirmationModule)
  },
  {
    path: 'Register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'ResetPasswordConfirmation',
    loadChildren: () =>
      import('./reset-password-confirmation/reset-password-confirmation.module').then(
        m => m.ResetPasswordConfirmationModule
      )
  },
  {
    path: 'ResetPassword',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'TwoFactorAuth/Configure',
    canActivate: [IsLoggedInAuthGuardService],
    loadChildren: () => import('./2fa/configure/configure-2fa.module').then(m => m.ConfigureTwoFactorAuthModule)
  },
  {
    path: 'TwoFactorAuth/Interstitial',
    canActivate: [IsLoggedInAuthGuardService],
    loadChildren: () =>
      import('./2fa/interstitial/interstitial-2fa.module').then(m => m.InterstitialTwoFactorAuthModule)
  },
  {
    path: 'Invite/Portal/:nonce',
    loadChildren: () =>
      import('./portal-invite-confirmation/portal-invite-confirmation.module').then(
        m => m.PortalInviteConfirmationModule
      )
  },
  {
    path: 'Access/Portal/Reports/:token',
    loadChildren: () => import('./portal-access/reports/reports.module').then(m => m.AccessReportsModule)
  },
  {
    path: 'Static/Reports/AssetInventory',
    loadChildren: () =>
      import('./static/reports/asset-inventory/asset-inventory.module').then(m => m.AssetInventoryModule)
  },
  {
    path: 'Static/Reports/PatchCompliance',
    loadChildren: () =>
      import('./static/reports/patch-compliance/patch-compliance.module').then(m => m.PatchComplianceModule)
  },
  {
    path: 'Static/Reports/Antivirus',
    loadChildren: () => import('./static/reports/antivirus/antivirus.module').then(m => m.AntivirusModule)
  },
  {
    path: 'Static/Forms/ViewRequest',
    loadChildren: () => import('./static/forms/view-request.module').then(m => m.ViewRequestStaticModule)
  },
  {
    path: '',
    loadChildren: () => import('./main-portal/main-portal.module').then(m => m.MainPortalModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [AppComponent, UnsafeOperationDialog],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    DndModule.forRoot(),
    MatDialogModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 7500,
        horizontalPosition: 'center'
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        width: '350px'
      }
    },
    { provide: 'generateXhrLogs', useValue: false },
    AuthService,
    RestService,
    NavigationService,
    IsLoggedInAuthGuardService,
    IsHrManagerAuthGuardService,
    IsAdminAuthGuardService,
    IsStaffAuthGuardService,
    JWTService,
    TicketService,
    AssetInventoryService,
    PatchComplianceService,
    StaticFormService
  ],
  entryComponents: [UnsafeOperationDialog],
  bootstrap: [AppComponent]
})
export class AppModule {}
