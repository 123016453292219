import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

import { BehaviorSubject } from 'rxjs';

export interface MenuItem {
  active?: boolean;
  children?: ChildItem[];
  description?: string;
  disabled?: boolean;
  icon?: string;
  id?: string;
  link?: string;
  name?: string;
  title?: string;
  tooltip?: string;
  type: string;
}

export interface ChildItem {
  active?: boolean;
  beta?: boolean;
  children?: ChildItem[];
  icon?: string;
  id?: string;
  link?: string;
  name: string;
  parentId?: string;
  type?: string;
}

interface RouterOptions {
  scrollToTop: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public readonly defaultMenuItems: MenuItem[] = [
    {
      name: 'Dashboard',
      icon: 'i-bar-chart',
      link: '/Dashboard',
      type: 'link'
    },
    {
      name: 'Support Requests',
      icon: 'i-consulting',
      link: '/MySupportTickets',
      type: 'link'
    },
    {
      name: 'My IT Team',
      icon: 'i-conference',
      link: '/MyTeam',
      type: 'link'
    }
  ];

  public readonly hrManagerMenuItems: MenuItem[] = [
    {
      name: 'Employee Management',
      icon: 'i-id-2',
      type: 'link',
      link: '/Admin/EmployeeManagement'
    }
  ];

  public adminMenuItems: MenuItem[] = [
    {
      name: 'Admin',
      icon: 'i-gear',
      type: 'dropdown',
      children: [
        {
          name: 'Invoices',
          icon: 'i-receipt-4',
          type: 'link',
          link: '/Admin/Invoices'
        },
        {
          name: 'Reports',
          icon: 'i-files',
          type: 'dropdown',
          children: [
            {
              name: 'Service Tickets',
              type: 'link',
              link: '/Admin/TicketReport'
            },
            {
              name: 'Management Reports',
              type: 'link',
              link: '/Admin/ManagementReport'
            },
            {
              name: 'Patch Compliance',
              type: 'link',
              link: '/Admin/PatchComplianceReport',
              beta: true
            },
            {
              name: 'Antivirus',
              type: 'link',
              link: '/Admin/AntivirusReport',
              beta: true
            }
          ]
        },
        {
          name: 'Asset Inventory',
          icon: 'i-computer-2',
          type: 'link',
          link: '/Admin/AssetInventory',
          beta: true
        },
        {
          name: 'Employee Management',
          icon: 'i-id-2',
          type: 'link',
          link: '/Admin/EmployeeManagement'
        },
        {
          name: 'Workflows',
          icon: 'i-check',
          type: 'link',
          link: '/Admin/Workflows/Policies'
        },
        {
          name: 'Company Settings',
          icon: 'i-gears',
          type: 'link',
          link: '/Admin/Settings'
        }
      ]
    }
  ];

  public staffMenuItems: MenuItem[] = [
    {
      name: 'Varsity Staff Panel',
      icon: 'i-administrator',
      link: '/StaffPanel',
      type: 'link'
    }
  ];

  public sidebarState: {
    sidenavOpen: boolean;
    childnavOpen: boolean;
  } = {
    sidenavOpen: true,
    childnavOpen: false
  };

  public readonly menuItems: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>(this.defaultMenuItems);
  public readonly menuItems$;

  public readonly routerOptions: RouterOptions = {
    scrollToTop: false
  };

  constructor(private readonly auth: AuthService) {
    this.auth.securityLevel.subscribe(securityLevel => {
      switch (securityLevel) {
        case 1:
        case 2:
        case 3:
          this.buildUserMenu();
          break;

        case 4:
        case 5:
          this.buildHrManagerMenu();
          break;

        case 6:
          this.buildAdminMenu();
          break;
      }
    });

    this.menuItems$ = this.menuItems.asObservable();
  }

  buildUserMenu(): void {
    this.menuItems.next([...this.defaultMenuItems]);
    this.checkStaffPanelAccess();
  }

  buildHrManagerMenu(): void {
    this.menuItems.next([...this.defaultMenuItems, ...this.hrManagerMenuItems]);
    this.checkStaffPanelAccess();
  }

  buildAdminMenu(): void {
    this.menuItems.next([...this.defaultMenuItems, ...this.adminMenuItems]);
    this.checkStaffPanelAccess();
  }

  checkStaffPanelAccess(): void {
    if (this.auth.getUser().staffFlag) {
      const menuItems = this.menuItems.getValue();
      this.menuItems.next([...menuItems, ...this.staffMenuItems]);
    }
  }
}
