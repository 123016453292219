import { ErrorHandler } from '@angular/core';
import { Routes } from '@angular/router';
import { IsLoggedInAuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';
import { version } from '../../package.json';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: 'https://660a602bed53462d989341e9638ed4f6@sentry.io/1285874',
    release: 'varsity-portal-front@' + version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    enabled: environment.production ? true : false
});
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
var ɵ0 = function () { return import("./login/login.module.ngfactory").then(function (m) { return m.LoginModuleNgFactory; }); }, ɵ1 = function () { return import("./main-portal/logout/logout.module.ngfactory").then(function (m) { return m.LogoutModuleNgFactory; }); }, ɵ2 = function () {
    return import("./register-confirmation/register-confirmation.module.ngfactory").then(function (m) { return m.RegisterConfirmationModuleNgFactory; });
}, ɵ3 = function () { return import("./register/register.module.ngfactory").then(function (m) { return m.RegisterModuleNgFactory; }); }, ɵ4 = function () {
    return import("./reset-password-confirmation/reset-password-confirmation.module.ngfactory").then(function (m) { return m.ResetPasswordConfirmationModuleNgFactory; });
}, ɵ5 = function () { return import("./reset-password/reset-password.module.ngfactory").then(function (m) { return m.ResetPasswordModuleNgFactory; }); }, ɵ6 = function () { return import("./2fa/configure/configure-2fa.module.ngfactory").then(function (m) { return m.ConfigureTwoFactorAuthModuleNgFactory; }); }, ɵ7 = function () {
    return import("./2fa/interstitial/interstitial-2fa.module.ngfactory").then(function (m) { return m.InterstitialTwoFactorAuthModuleNgFactory; });
}, ɵ8 = function () {
    return import("./portal-invite-confirmation/portal-invite-confirmation.module.ngfactory").then(function (m) { return m.PortalInviteConfirmationModuleNgFactory; });
}, ɵ9 = function () { return import("./portal-access/reports/reports.module.ngfactory").then(function (m) { return m.AccessReportsModuleNgFactory; }); }, ɵ10 = function () {
    return import("./static/reports/asset-inventory/asset-inventory.module.ngfactory").then(function (m) { return m.AssetInventoryModuleNgFactory; });
}, ɵ11 = function () {
    return import("./static/reports/patch-compliance/patch-compliance.module.ngfactory").then(function (m) { return m.PatchComplianceModuleNgFactory; });
}, ɵ12 = function () { return import("./static/reports/antivirus/antivirus.module.ngfactory").then(function (m) { return m.AntivirusModuleNgFactory; }); }, ɵ13 = function () { return import("./static/forms/view-request.module.ngfactory").then(function (m) { return m.ViewRequestStaticModuleNgFactory; }); }, ɵ14 = function () { return import("./main-portal/main-portal.module.ngfactory").then(function (m) { return m.MainPortalModuleNgFactory; }); };
var routes = [
    {
        path: 'Login',
        loadChildren: ɵ0
    },
    {
        path: 'Logout',
        loadChildren: ɵ1
    },
    {
        path: 'RegistrationConfirmation',
        loadChildren: ɵ2
    },
    {
        path: 'Register',
        loadChildren: ɵ3
    },
    {
        path: 'ResetPasswordConfirmation',
        loadChildren: ɵ4
    },
    {
        path: 'ResetPassword',
        loadChildren: ɵ5
    },
    {
        path: 'TwoFactorAuth/Configure',
        canActivate: [IsLoggedInAuthGuardService],
        loadChildren: ɵ6
    },
    {
        path: 'TwoFactorAuth/Interstitial',
        canActivate: [IsLoggedInAuthGuardService],
        loadChildren: ɵ7
    },
    {
        path: 'Invite/Portal/:nonce',
        loadChildren: ɵ8
    },
    {
        path: 'Access/Portal/Reports/:token',
        loadChildren: ɵ9
    },
    {
        path: 'Static/Reports/AssetInventory',
        loadChildren: ɵ10
    },
    {
        path: 'Static/Reports/PatchCompliance',
        loadChildren: ɵ11
    },
    {
        path: 'Static/Reports/Antivirus',
        loadChildren: ɵ12
    },
    {
        path: 'Static/Forms/ViewRequest',
        loadChildren: ɵ13
    },
    {
        path: '',
        loadChildren: ɵ14
    },
    { path: '**', redirectTo: '' }
];
var ɵ15 = {
    appearance: 'fill'
}, ɵ16 = {
    duration: 7500,
    horizontalPosition: 'center'
}, ɵ17 = {
    hasBackdrop: true,
    width: '350px'
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
