import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatchComplianceService {
  private readonly patchComplianceDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getPdfData(): any {
    return this.patchComplianceDataSubject.getValue();
  }

  setPdfData(data: any): void {
    this.patchComplianceDataSubject.next(data);
  }
}
