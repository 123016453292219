/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./operation-insecure";
var styles_UnsafeOperationDialog = [];
var RenderType_UnsafeOperationDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_UnsafeOperationDialog, data: {} });
export { RenderType_UnsafeOperationDialog as RenderType_UnsafeOperationDialog };
export function View_UnsafeOperationDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cookies Must Be Enabled"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You must enable cookies to continue using this application."]))], null, null); }
export function View_UnsafeOperationDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UnsafeOperationDialog_0, RenderType_UnsafeOperationDialog)), i0.ɵdid(1, 49152, null, 0, i1.UnsafeOperationDialog, [], null, null)], null, null); }
var UnsafeOperationDialogNgFactory = i0.ɵccf("ng-component", i1.UnsafeOperationDialog, View_UnsafeOperationDialog_Host_0, {}, {}, []);
export { UnsafeOperationDialogNgFactory as UnsafeOperationDialogNgFactory };
