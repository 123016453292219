export const SQL_DATE_FORMAT = 'YYYY-MM-DD';

export const FIELD_NOT_AVAILABLE = '-';

export const USER_ROLE = {
  USER: 1,
  HR_MANAGER: 4,
  ADMIN: 6
};

export const OO_REQUEST_STATUS = {
  DRAFT: 0,
  SUBMITTED: 1,
  APPROVED: 2,
  REJECTED: 3
};

export const PAYMENT_TYPE = {
  BANK_VIA_PLAID: 'bank_via_plaid',
  BANK_VIA_MICRODEPOSITS: 'bank_via_microdeposits',
  CARD: 'card'
};

export const DEFAULT_ERROR_MESSAGE = 'An error occurred';
export const CREDIT_CARD_FEE_MULTIPLIER = 0.032;

export const TEMPLATE_DATE_FORMAT = 'MMMM d, yyyy';
export const TEMPLATE_DATETIME_FORMAT = 'MMMM d, yyyy h:mm:ss a';

export const INTERSTITIAL_VERIFICATION_CODE_LENGTH = 6;

export const LOGO_URL = 'https://s3.us-east-2.amazonaws.com/s3.static.teamview/varsity_logo_red.png';
