import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthService } from './services/auth.service';
import { NavigationService } from './services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public minimized: boolean = false;
  public showDashboardElements: boolean = false;
  public showModal: boolean = false;

  constructor(
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly navService: NavigationService
  ) {}

  ngOnInit() {
    // Forces the window to scroll to top upon the NavigationEnd router event
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (this.navService.routerOptions.scrollToTop) {
          window.scrollTo(0, 0);
        }
      }
    });

    const domain = window.location.hostname;
    if (domain && domain.toLocaleLowerCase() === 'varsitysf') {
      const url = window.location.href || document.URL;

      if (url) {
        window.open(url.replace('varsitysf', 'varsitytech'), '_self');
      }
    }
  }
}
